<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="影院名称" name="cinemaName" class="ui-form__item">
            <a-input v-model:value="formState.cinemaName" placeholder="请输入影院名称"></a-input>
          </a-form-item>

          <a-form-item label="影片名称" name="filmName" class="ui-form__item">
            <a-input v-model:value="formState.filmName" placeholder="请输入影片名称"></a-input>
          </a-form-item>

          <a-form-item label="用户ID" name="userID" class="ui-form__item">
            <a-input v-model:value="formState.userID" placeholder="请输入用户ID"></a-input>
          </a-form-item>

          <a-form-item label="用户信息" name="userInfo" class="ui-form__item">
            <a-input v-model:value="formState.userInfo" placeholder="请输入用户信息"></a-input>
          </a-form-item>
        </a-row>

        <a-row v-show="showAll">
          <a-form-item label="影片编码" name="filmCode" class="ui-form__item">
            <a-input v-model:value="formState.filmCode" placeholder="请输入影片编码"></a-input>
          </a-form-item>

          <a-form-item label="座位" name="seatName" class="ui-form__item">
            <a-input v-model:value="formState.seatName" placeholder="请输入座位"></a-input>
          </a-form-item>

          <a-form-item label="订单号" name="orderNo" class="ui-form__item">
            <a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
          </a-form-item>

          <a-form-item label="取票状态" name="printStatusList" class="ui-form__item">
            <a-select mode="multiple" v-model:value="formState.printStatusList" placeholder="请选择取票状态" allow-clear style="width: 195px;">
              <a-select-option :value="0">未取票</a-select-option>
              <a-select-option :value="1">已取票</a-select-option>
              <a-select-option :value="2">已退票</a-select-option>
              <a-select-option :value="3">已改签</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="优惠方式" name="discountType" class="ui-form__item">
            <a-select v-model:value="formState.discountType" placeholder="请选择优惠方式" allow-clear style="width: 195px;">
              <a-select-option :value="1">优惠券</a-select-option>
              <a-select-option :value="2">一卡通</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="出票渠道" name="dataSource" class="ui-form__item">
            <a-select v-model:value="formState.dataSource" placeholder="请选择出票渠道" allow-clear style="width: 195px;">
              <a-select-option :value="2">凤凰云智</a-select-option>
              <a-select-option :value="3">辰星云3.0</a-select-option>
              <a-select-option :value="4">辰星云4.0</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="支付方式" name="payMessageType" class="ui-form__item">
            <a-select v-model:value="formState.payMessageType" placeholder="请选择支付方式" allow-clear style="width: 195px;">
              <a-select-option :value="'wx_lite'">微信支付</a-select-option>
              <a-select-option :value="'alipay'">支付宝支付</a-select-option>
              <a-select-option :value="'card'">一卡通支付</a-select-option>
              <a-select-option :value="'zero'">0元支付</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="是否售后中" name="isAfterSale" class="ui-form__item">
            <a-select v-model:value="formState.isAfterSale" placeholder="请选择售后状态" allow-clear style="width: 195px;">
              <a-select-option :value="0">否</a-select-option>
              <a-select-option :value="1">售后中</a-select-option>
              <a-select-option :value="2">改签中</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="订单状态" name="status" class="ui-form__item">
            <a-select v-model:value="formState.status" placeholder="请选择订单状态" allow-clear style="width: 195px;">
              <a-select-option :value="'CHECKOUT_SUCCESS'">已付款</a-select-option>
              <a-select-option :value="'TRADE_FINISH'">已取票</a-select-option>
              <a-select-option :value="'AFTERSALE_FINISH'">全部售后</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="购买时间"  name="createTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.createTime"></a-range-picker>
          </a-form-item>

          <a-form-item label="放映时间"  name="showTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.showTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a style="margin-bottom: 10px" @click="showAll = !showAll">
            {{ showAll ? '收起' :'展开' }}
            <Icon v-show="!showAll" icon="DownOutlined"></Icon>
            <Icon v-show="showAll" icon="UpOutlined"></Icon>
          </a>
        </a-row>

        <a-row>
          <a-col :span="18">
            <span v-permission="['reportForms_sale_film_export']">
				<ExportReport type="cinemaOrderItemExportStrategy" :searchData="searchData"></ExportReport>
			</span>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 4950 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'userInfo'">
              <div>用户昵称：{{ record.nickname ? record.nickname : '-' }}</div>
              <div>手机号：{{ record.phone ? record.phone : '-' }}</div>
            </template>
            <template v-if="column.key === 'createTime'">
              {{ transDateTime(record.createTime, 1) }}
            </template>
            <template v-if="column.key === 'showStartTime'">
              {{ transDateTime(record.showStartTime, 1) }}
            </template>
            <template v-if="column.key === 'partitionName'">
              {{ record.seatInfoDetail.partitionName }}
            </template>
            <template v-if="column.key === 'dataSourceServe'">
              <div>增值服务费：{{ record.seatInfoDetail?.ticketFee }}</div>
              <div>平台服务费：{{ record.seatInfoDetail?.serviceFee }}</div>
            </template>
            <template v-if="column.key === 'serviceCharge'">
              {{ record.seatInfoDetail?.ticketCharge }}
            </template>
            <template v-if="column.key === 'seatName'">
              {{ record.seatInfoDetail.columnCode }}排
              {{ record.seatInfoDetail.rowCode }}座
            </template>
            <template v-if="column.key === 'settlePrice'">
              {{ record.seatInfoDetail.settlePrice }}
            </template>
            <template v-if="column.key === 'seatPrice'">
              {{ record.seatInfoDetail.columnCode }}
            </template>
            <template v-if="column.key === 'couponType'">
              <div v-if="record.couponType === 1">代金券</div>
              <div v-if="record.couponType === 2">抵扣券</div>
            </template>
            <template v-if="column.key === 'payMessageType'">
              <div v-if="record.payMessageType === 'wx_lite'">微信小程序</div>
              <div v-if="record.payMessageType === 'alipay'">支付宝</div>
              <div v-if="record.payMessageType === 'card'">一卡通</div>
            </template>
            <template v-if="column.key === 'printTime'">
              {{ transDateTime(record.printTime, 1) }}
            </template>
            <template v-if="column.key === 'afterSaleStatus'">
              <div v-if="record.afterSaleStatus === 0">未发起</div>
              <div v-if="record.afterSaleStatus === 1">售后中</div>
              <div v-if="record.afterSaleStatus === 2">完成售后</div>
              <div v-if="record.afterSaleStatus === 3">改签中</div>
              <div v-if="record.afterSaleStatus === 4">完成改签</div>
            </template>
            <template v-if="column.key === 'status'">
              <div v-if="record.status === 'CHECKOUT_SUCCESS'">已付款</div>
              <div v-if="record.status === 'TRADE_FINISH'">已取票</div>
              <div v-if="record.status === 'AFTERSALE_FINISH'">全部售后</div>
            </template>
            <template v-if="column.key === 'dataSource'">
              <div v-if="record.dataSource === 2">凤凰云智</div>
              <div v-if="record.dataSource === 3">辰星云3.0</div>
              <div v-if="record.dataSource === 4">辰星云4.0</div>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import { getCinemaItemList } from "@/service/modules/report";
import ExportReport from '@/components/exportReport/exportReport.vue';
import {getCinemaOrderDetail} from "../../../../service/modules/report";
export default {
  components: {
    Icon,
    ExportReport,
  },
  data() {
    return {
      showAll: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      searchData: {},
      modelRef: {},
      formState: {},
      columns: [{
        title: '订单编号',
        dataIndex: 'orderNo',
      }, {
        title: "票号",
        dataIndex: "ticketNo",
      }, {
        title: '用户ID',
        dataIndex: 'userId',
      }, {
        title: '用户信息',
        key: 'userInfo',
      }, {
        title: '购买时间',
        key: 'createTime',
      }, {
        title: '影片编码',
        dataIndex: 'filmCode',
      }, {
        title: '影片名称',
        dataIndex: 'filmName',
      }, {
        title: '放映时间',
        key: 'showStartTime',
      }, {
        title: '影院名称',
        dataIndex: 'cinemaName',
      }, {
        title: '影厅名称',
        dataIndex: 'hallName',
      }, {
        title: '分区',
        key: 'partitionName',
      }, {
        title: '售票系统服务费',
        key: 'dataSourceServe',
      }, {
        title: '网络代售费',
        key: 'serviceCharge',
      }, {
        title: '座位',
        key: 'seatName',
      }, {
        title: '座位价格',
        key: 'settlePrice',
      }, {
        title: '优惠券',
        key: 'couponType',
      }, {
        title: '优惠券优惠',
        dataIndex: 'couponDeduction',
      }, {
        title: '一卡通优惠',
        dataIndex: 'cardDiscountDeduction',
      }, {
        title: '实付金额',
        dataIndex: 'payment',
      }, {
        title: '支付方式',
        key: 'payMessageType',
      }, {
        title: '取票时间',
        key: 'printTime',
      }, {
        title: '售后状态',
        key: 'afterSaleStatus',
      }, {
        title: '订单状态',
        key: 'status',
      }, {
        title: '退款服务费',
        dataIndex: 'afterSaleTotalServiceFee',
      }, {
		title: '改签服务费',
		dataIndex: 'changeTotalServiceFee'
	  }, {
        title: '原订单',
        dataIndex: 'changeOrderNo',
      }, {
        title: '出票渠道',
        key: 'dataSource',
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    // 获取影票座位列表
    async getData() {
      if(this.formState.createTime && this.formState.createTime.length === 2) {
        this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
        this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
      }
      delete this.searchData.createTime;
      if(this.formState.endTime && this.formState.showTime.length === 2) {
        this.searchData.showStartTime = this.moment(this.formState.showTime[0].startOf('day')).unix();
        this.searchData.showEndTime = this.moment(this.formState.showTime[1].endOf('day')).unix();
      }
      delete this.searchData.showTime;
      this.loading = true;
      try {
        let ret = await getCinemaItemList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
